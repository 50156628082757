export function isNullOrUndefined<T>(obj: T | null | undefined): obj is null | undefined {
  return typeof obj === "undefined" || obj === null;
}

export function isDefined<T>(obj: T | null | undefined): obj is T {
  return !isNullOrUndefined(obj);
}

export function isEmptyObject(value: unknown): value is { [key: string]: never } {
  return (
    value !== null &&
    typeof value === "object" &&
    !(value instanceof Array) &&
    !(value instanceof Date) &&
    Object.keys(value).length === 0
  );
}

export function isNonEmptyString(value: unknown): value is string {
  return typeof value === "string" && value.trim().length > 0;
}

export function isEmptyString(value: unknown): value is string {
  return typeof value === "string" && value.trim().length === 0;
}
